@import url(https://fonts.googleapis.com/css?family=Prompt:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
body {
    background-color: #e32432;
    header {
        flex: 1;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 5px 15px;
    }
    .container {
        display: flex;
        align-items: center;
        padding: 0 15px;
        max-width: calc(100% - 30px);
        width: 1366px;
        justify-content: space-between;
    }
    header,
    header > .container {
        .logo {
            font-family: "Prompt", sans-serif;
            font-style: italic;
            font-weight: bold;
            font-size: 1.5em;
            color: #fff;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        }
        ul {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                display: inline-block;
                a {
                    display: block;
                    &.imageArea {
                        padding: 2px;
                        border-radius: 100%;
                        border: 2px solid #f9f8fa;
                    }
                    img {
                        width: 2.8rem;
                        height: 2.8rem;
                        border-radius: 100%;
                    }
                }
                &.dropdown-float > ul {
                    position: absolute;
                    height: 350px;
                    border-radius: 10px;
                    margin-top: 5px;
                    right: 15px;
                    background-color: #ffffff;
                    display: block;
                    width: 200px;

                    li {
                        display: inline-block;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.3);
                        a {
                            display: flex;
                            padding: 8px;
                            justify-content: space-between;
                            align-items: center;
                            flex-direction: row;
                        }
                    }
                }
            }
        }
    }
}
