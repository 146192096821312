@import url("https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.input-group {
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: stretch;
    align-items: stretch;
    min-height: 40px;
    margin-top: 5px;
    margin-bottom: 15px;
    &:hover {
        filter: brightness(0.98);
    }
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-width: 45px;
        padding: 0 3px;
        background-color: #fdfdfd;
        border: none;
        border-radius: 8px;
        box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.1);
        z-index: 99;
    }
    input.form-control {
        flex: 1;
        border: none;
        padding: 0 0 0 8px;
        border-radius: 8px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        &:focus {
            outline: none;
        }
    }
    &.inline {
        flex-direction: row;
        span {
            flex: 2;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        input.form-control {
            flex: 16;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
    button.form-control {
        width: 100%;
        font-size: 17px;
        font-family: "Prompt", sans-serif;
        text-transform: capitalize;
        font-weight: 600;
    }
}
main {
    display: flex;
    flex: 1;
    height: 100vh;
    align-items: stretch;
    flex-direction: row;

    aside {
        transition: 0.2s linear;
        display: flex;
        flex: 10;
        justify-content: center;
        align-items: center;
        background-color: #e32432;
        flex-direction: column;
        h1 {
            display: inline-block;
            margin: 0;
            font-family: "Prompt", sans-serif;
            font-style: italic;
            font-weight: 600;
            font-size: 3.6em;
            color: #fff;
        }
        a {
            display: block;
            color: #000;
            text-decoration: none;
        }
    }
    div.content {
        transition: 0.2s linear;
        display: flex;
        flex: 1;
        justify-content: stretch;
        align-items: stretch;
        background-color: #f8f9fa;

        &:hover {
            transition: 0.2s linear;
            flex: 20;
            box-shadow: -4px 0px 15px rgba(0, 0, 0, 0.3);
            z-index: 999;
        }
        .content-indicate {
            transition: 0.2s linear;
            flex: 3;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &.arrow {
                // font-size: 40px;
                padding: 35px 0 0 0;
                span {
                    margin-top: 15px;
                }
            }
            span {
                font-size: 18px;
            }
        }
        .content-container {
            transition-delay: 3s;
            display: none;
            flex: 14;
            justify-content: stretch;
            align-items: center;
            padding: 0 15px;
            opacity: 0;

            .formContent,
            .formContent form {
                display: flex;
                flex: 1;
                justify-content: stretch;
                align-items: center;
                flex-direction: column;
                .formContent-area {
                    flex: 1;
                    width: 100%;
                    &.formContent-header {
                        text-align: center;
                        padding: 15px 0;
                        h1 {
                            display: inline-block;
                            margin: 0;
                            font-family: "Roboto", sans-serif;
                            font-weight: 600;
                            font-size: 2.2em;
                        }
                    }
                }
            }
            .formContent form {
                max-width: 80%;
                width: 300px;
            }
        }
        &:hover .content-container {
            transition-delay: 3s;
            transition: 3s linear;
            opacity: 1;
            display: flex;
        }
    }
}
